<template>
    <div>
        <div
            class="alert alert-white rounded products-advertise-info"
            :class="{
                'alert-info': !advertiseLimitAchieved,
                'alert-success': advertiseLimitAchieved,
                pulse: !advertiseLimitAchieved,
            }"
        >
            <div class="icon"><i class="el-icon-trophy"></i></div>
            <div class="title">
                <div>Promovare produse</div>
                <div v-if="!inDashboard">
                    <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-info"
                        plain
                        @click.native="openAdvertisingInfoDialog"
                        >Detalii
                    </el-button>
                </div>

                <div v-else>
                    <el-button type="primary" size="small" icon="el-icon-link" plain @click.native="gotoProducts"
                        >{{ advertiseLimitAchieved ? 'Gestioneaza produsele' : 'Alege produsele' }}
                    </el-button>
                </div>
            </div>
            <div class="mb-1">
                <div v-if="advertiseLimitAchieved">
                    Felicitari! Ai selectat cele {{ advertiseLimit }} produse pentru promovare!
                </div>
                <div v-else>Alege cele {{ advertiseLimit }} produse pentru <strong>promovare gratuita</strong>!</div>
            </div>

            <el-progress
                :status="advertiseLimitAchieved ? 'success' : undefined"
                :percentage="advertiseStatusPercentage"
                :format="advertiseStatusBarFormat"
            ></el-progress>
        </div>

        <el-dialog title="Promoveaza-ti produsele gratuit" :visible.sync="advertisingInfoDialog" width="80%">
            <div class="advertising-info-dialog-content">
                <p>
                    Ai ocazia sa alegi pana la 10 dintre cele mai populare produse ale tale pentru a le promova gratuit
                    pe platforma noastra. Acest lucru iti poate creste vizibilitatea si vanzarile. Te rog sa selectezi
                    produsele pe care le consideri cele mai reprezentative si cu potential mare de a atrage clienti.
                </p>
                <p><strong>Instructiuni:</strong></p>
                <ol>
                    <li>
                        In lista de mai jos, bifeaza produsele pe care vrei sa le promovezi. Poti alege maximum 10
                        produse.
                    </li>
                    <li>Contorul iti arata cate produse ai selectat deja.</li>
                </ol>
                <p><strong>Note:</strong></p>
                <ul>
                    <li>
                        Daca incerci sa selectezi mai mult de 10 produse, sistemul nu iti va permite si vei primi o
                        notificare.
                    </li>
                    <li>
                        Daca doresti sa schimbi selectia, debifeaza produsele selectate anterior si alege altele pana
                        cand esti multumit de selectie.
                    </li>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeAdvertisingInfoDialog">Inchide</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getAdvertiseInfo } from '@/api/produse';
import { EventBus } from '@/utils/Bus.js';

export default {
    props: {
        inDashboard: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        advertiseInfo: {
            limit: 10,
            products: [],
        },
        advertisingInfoDialog: false,
    }),
    methods: {
        async getAdvertiseInfo() {
            try {
                const result = await getAdvertiseInfo();
                if (result?.message == null) {
                    return;
                }
                this.$set(this.advertiseInfo, 'products', result.message?.products ?? []);
                this.$set(this.advertiseInfo, 'limit', result.message?.limit ?? 10);
            } catch (e) {
                console.log(e);
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'getAdvertiseInfo',
                    },
                });
            }
        },
        advertiseStatusBarFormat() {
            return `${this.advertiseInfo.products.length}/${this.advertiseInfo.limit}`;
        },
        openAdvertisingInfoDialog() {
            this.advertisingInfoDialog = true;
        },
        closeAdvertisingInfoDialog() {
            this.advertisingInfoDialog = false;
        },
        gotoProducts() {
            this.$router.push({ name: 'Lista produse' });
        },
    },
    computed: {
        advertisedProducts() {
            return this.advertiseInfo.products;
        },
        advertiseLimit() {
            return this.advertiseInfo.limit;
        },
        advertiseLimitAchieved() {
            return this.advertiseInfo.products.length >= this.advertiseInfo.limit;
        },
        advertiseStatusPercentage() {
            return (this.advertiseInfo.products.length / this.advertiseInfo.limit) * 100;
        },
    },

    mounted() {
        EventBus.$on('product-advertised', this.getAdvertiseInfo);
        return this.getAdvertiseInfo();
    },
    beforeDestroy() {
        EventBus.$off('product-advertised', this.getAdvertiseInfo);
    },
};
</script>
<style lang="scss" scoped>
.products-advertise-info {
    .title {
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &.pulse {
        animation: glow 1.2s infinite;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px rgba(255, 96, 29, 0.5);
    }
    50% {
        box-shadow: 0 0 20px rgba(255, 96, 29, 1);
    }
    100% {
        box-shadow: 0 0 5px rgba(255, 96, 29, 0.5);
    }
}

.advertising-info-dialog-content {
    word-break: break-word;
}
</style>
